import type { ButtonHTMLAttributes, ReactNode } from 'react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { DocSearchModal } from '@docsearch/react'
import type { InternalDocSearchHit, StoredDocSearchHit } from '@docsearch/react'
import { createPortal } from 'react-dom'

type SearchContextProps = {
  closeSearchModal?: () => void
  openSearchModal?: () => void
}

const SearchContext = createContext<SearchContextProps>({})

type ProviderProps = {
  children: JSX.Element
}

export function SearchProvider({ children }: ProviderProps) {
  const [isOpen, setIsOpen] = useState(false)

  const closeSearchModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const openSearchModal = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  useEffect(() => {}, [setIsOpen])

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeSearchModal()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [closeSearchModal])

  return (
    <>
      <SearchContext.Provider value={{ closeSearchModal, openSearchModal }}>{children}</SearchContext.Provider>

      {isOpen
        ? createPortal(
            <DocSearchModal
              apiKey={window.ENV.ALGOLIA_PUBLIC_API_KEY || ''}
              appId={window.ENV.ALGOLIA_APP_ID || ''}
              indexName={window.ENV.ALGOLIA_INDEX_NAME || ''}
              disableUserPersonalization={true}
              hitComponent={Hit}
              initialScrollY={window.scrollY}
              onClose={closeSearchModal}
              placeholder='Search documentation'
            />,
            document.body
          )
        : null}
    </>
  )
}

type HitProps = {
  hit: InternalDocSearchHit | StoredDocSearchHit
  children: ReactNode
}

function Hit({ hit, children }: HitProps) {
  if (hit.type === 'lvl0') {
    return (
      <a href={hit.url}>
        <span className='DocSearch-Hit-Container'>
          <span className='DocSearch-Hit-content-wrapper'>
            <span className='DocSearch-Hit-title'>Overview</span>
          </span>
        </span>
      </a>
    )
  }

  return <a href={hit.url}>{children}</a>
}

type Props = ButtonHTMLAttributes<HTMLButtonElement>

export function SearchButton(props: Props) {
  const { openSearchModal } = useContext(SearchContext)

  return <button onClick={openSearchModal} type='button' {...props} />
}
